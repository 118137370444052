<template>
  <div>
    <div class="ll-box">
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('Delivery Order') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{ $t('PY-Waybill') }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <!-- Add Btn -->
      <div class="d-flex justify-content-end">
        <b-button
          variant="info"
          @click="serachFunc"
        >
          <span class="text-nowrap">{{ $t('Search Customer') }}</span>
        </b-button>
        <b-button
          variant="info"
          :to="{ name: 'create-mapping' }"
          style="margin-left:5px"
        >
          <span class="text-nowrap">{{ $t('Create Mapping') }}</span>
        </b-button>
      </div>
    </div>
    <!-- table -->
    <b-table
      ref="refUserListTable"
      class="position-relative ll-table"
      :items="rows"
      :fields="columns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      :striped="true"
      :hover="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @sort-changed="onSortChange"
      v-permission="[143]"
      :sticky-header="true"
      :busy="isBusy"
    >
    </b-table>
    <!-- show pages -->
    <div class="d-flex justify-content-between flex-wrap ll-p">
      <div class="d-flex align-items-center mb-0">
        <span class="text-nowrap ">
          Showing 1 to
        </span>
        <b-form-select
          v-model="size"
          :options="pageOptions"
          class="mx-1"
          @input="handlePageChange"
        />
        <span class="text-nowrap"> of {{ totalSize }} entries </span>
      </div>
      <div>
        <b-pagination
          v-model="page"
          :total-rows="totalSize"
          :per-page="size"
          first-number
          last-number
          class="mb-0 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @change="handleChangePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
    <b-modal
      id="modal-details"
      ref="modal-details"
      centered
      title="Search Customer"
      hide-footer
      v-model="show"
    >
      <div class="flex-grow-1">
        <b-card >
          <!-- Search Customer -->
          <searchCustomer @listenModel="listenSearchCustomer"></searchCustomer>
        </b-card>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BButton,
  // BTooltip,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BSidebar,
  VBToggle,
  BTable,
  BInputGroupPrepend,
  BInputGroup,
  BBreadcrumb,
  BBreadcrumbItem,
  BBadge,
  BOverlay,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import permission from '@core/directives/permission/index.js'
import searchCustomer from './components/searchCustomer.vue'
export default {
  components: {
    BPagination,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BButton,
    // BTooltip,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BSidebar,
    vSelect,
    BTable,
    BInputGroupPrepend,
    BInputGroup,
    BBreadcrumb,
    BBreadcrumbItem,
    BBadge,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    AppCollapse,
    AppCollapseItem,
    BOverlay,
    BSpinner,
    searchCustomer
  },
  directives: {
    'b-toggle': VBToggle,
    permission,
  },
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      pageOptions: [20,50, 100, 200],
      page: null, // 当前页码
      size: 15, // 每页个数
      totalSize: null, // 总个数
      totalPage: null, // 总页数
      columns: [
        { key: 'createdAt', label: this.$t('Creation Time'), sortable: true,thStyle: { width:'180px'} },
        { key: 'displayName', label: this.$t('Customer'), sortable: true },
        { key: 'orderSequenceFrom', label: this.$t('From'), sortable: true },
        { key: 'orderSequenceTo', label: this.$t('To'), sortable: true },
        { key: 'qty', label: this.$t('Qty'), sortable: true },
        { key: 'operator', label: this.$t('Operator'), sortable: true ,thStyle: { width:'180px'}},
      ],
      rows: [],
      searchTerm: '',
      statusTypeList: [],
      statusList: [
        { value: null, status: 'all' },
        { value: true, status: 'active' },
        { value: false, status: 'inactive' },
      ],
      formTime: '',
      toTime: '',
      condition: {
        page: '1',
        size: '20',
        orderMole: 0,
        orderBy: 'createdAt',
      },
      filterBg: {
        '--background': '#fff'
      },
      status: null,
      isBusy: true,
      time: null,
      show:false,
    }
  },
  computed: {},
  created() {
  },
  mounted() {
    this.getList()
  },
  methods: {
    listenSearchCustomer(val){this.show = val;this.getList();},
    // 首次查询
    getList() {
      let where = ""
      let params = this.condition
      for(var k in params){
          where +=k+"="+params[k] + "&"
      }
      this.$http.get(`/py/getPyOrdersQty?${where}`).then(res => {
        const info = res.data.data
        this.rows = info.content
        this.page = info.page
        this.size = info.size
        this.totalSize = info.totalSize
        this.totalPage = info.totalPage
        this.isBusy = false
      }).catch(error => console.log(error))
    },
    // 点击单行
    onRowClick() {
      // console.log('onRowClick')
    },
    onSearch() {
      // console.log('onSearch')
    },
    serachFunc(){
      this.$refs['modal-details'].show()
    },
    // 排序规则
    onSortChange(params) {
      const orderBy = params.sortBy
      const orderMole = params.sortDesc ? 0 : 1
      this.condition.orderBy = orderBy
      this.condition.orderMole = orderMole
      this.getList()
    },
    // 分页
    handleChangePage(page) {
      this.condition.page = page
      this.getList()
    },
    handlePageChange(active) {
      this.condition.size = active
      this.getList()
    },
  },
}
</script>
<style scoped>
/* .ll-p{
  position: fixed;
  bottom: 40px;
  right: 10px;
} */
.ll-btn{
  position: fixed;
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding-right: 6rem;
  width: 100%;
}
.ll-btnS{
  margin-right: 20px;
}
.ll-colBox{
  margin: auto;
}
.ll-outline{
  outline: none;
}
.ll-searchBtn div{
  background-color: #ff9f43;
  color: #fff;
  width: 60px;
  display: flex;
  justify-content: center;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.ll-box{
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 10px;
}
.ll-act{
  height: 15px;
  width: 15px;
}
.btn .feather{
  position: relative;
  top: -8px;
  left: -5px;
}
.ll-outline> div{
  background: var(--background);
}
</style>
<style>
[dir] .vgt-table.bordered th{
  padding: 10px;
  font-size: 12px;
}
table.vgt-table{
  font-size: 12px;
}
[dir=ltr] .b-sidebar.b-sidebar-right > .b-sidebar-header .close{
  margin-left: unset;
  margin-right: unset;
  font-size: 20px;
}
[dir=ltr] .b-sidebar.b-sidebar-right{
  width: 25%;
}
[dir] .page-item.active{
  height: 27px;
}
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
</style>
